import validate from 'validate.js';
export const validateFullName = fullName => {
  const constraints = {
    fullName: {
      presence: {
        allowEmpty: false,
        message: '^Please enter Full Name',
      },
      length: {
        minimum: 3,
        message: '^Must be at least 3 characters',
      },
    },
  };
  let fullNameValidation = validate({ fullName }, constraints);
  fullNameValidation = !fullNameValidation ? '' : fullNameValidation.fullName[0];
  return fullNameValidation;
};

export const validateEmail = email => {
  const constraints = {
    email: {
      presence: {
        allowEmpty: false,
        message: '^Please enter an email address, ',
      },
      email: {
        message: '^Please enter a valid email address',
      },
    },
  };
  let emailValidation = validate({ email }, constraints);
  emailValidation = !emailValidation ? '' : emailValidation.email[0];
  return emailValidation;
};

export const validatePassword = password => {
  const isUpperCaseExist = /([A-Z])/g.test(password);
  const isNumberExist = /\d/.test(password, 10);
  if (!isUpperCaseExist) {
    return ' one uppercase letter ';
  }
  if (!isNumberExist) {
    return 'a number ';
  }
  if (password.length < 8) {
    return ' at least 8 characters ';
  }
  return '';
};
